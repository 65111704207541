import React from 'react';
import SEO from '../components/seo';
import Layout from '../components/containers/layout';
import { PropertiesList } from '../components/propertiesList';

const IndexPage = () => {
  if (typeof window === 'undefined') return null;
  return (
    <Layout>
      <SEO title="Home" />
      <PropertiesList />
    </Layout>
  );
};

export default IndexPage;
