import React from 'react';
import gqlTag from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { Spinner } from 'reactstrap';

const AddPropertyButton = props => {
  const CREATE_PROPERTY = gqlTag`
    mutation {
      createPropertyFull(input: {}) {
        clientMutationId
        integer
      }
    }
  `;
  const [create, { loading, error }] = useMutation(CREATE_PROPERTY, {
    onCompleted: () => props.refetch(),
  });
  return (
    <>
      <div className="col-sd-12 col-md-6 col-lg-4 col-xl-3 p-0">
        <div
          className="add-property border-bottom border-right"
          style={{ height: '100%', minHeight: '80px' }}
          onClick={() => (!loading ? create() : false)}
          color={error ? 'danger' : ''}
        >
          <div
            className="d-flex flex-row align-items-center justify-content-center property-text"
            style={{ height: '100%' }}
          >
            {loading && <Spinner size="sm" color="success" />}
            {!loading && !error && 'Add property'}
            {error && `${error.message}. Try again`}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPropertyButton;
