import React from 'react';
import '../styles/propertyLogo.css';

const PropertyLogo = ({ property, size }) => {
  const { name, propertySettings } = property || {};
  const { logo: propertyLogo } = propertySettings || {};
  const logo =
    propertyLogo ||
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABrSURBVHgB7dABAQAgAIMwtH/XR9AgsAg72x5iF7kCkCsAuQKQKwC5ApArALkCkCsAuQKQKwC5ApArALkCkCsAuQKQKwC5ApArALkCkCsAuQKQKwC5ApArALkCkCsAuQKQKwC5ApArALkCkPtI5gRMZnZCewAAAABJRU5ErkJggg==';
  const title = propertyLogo ? `Logo - ${name}` : 'No logo';

  return (
    <img
      className={`property-logo ${size || 'large'}`}
      src={logo}
      alt={title}
      title={title}
    />
  );
};

export default PropertyLogo;
