import React, { useContext } from 'react';
import gqlTag from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { Spinner } from 'reactstrap';
import PropertyCard from './propertyCard';
import AddPropertyButton from './addPropertyButton';
import ErrorLine from '../components/containers/errorLine';
import { UserContext } from '../auth/protectedArea';
import { AbilityContext, Can } from '../auth/abilityContext';
import SEO from './seo';

export const GET_PROPERTIES_WITH_PMS = gqlTag`
  {
    propertiesList {
      id
      name
      status
      propertySettings {
        id
        logo
      }
      propertyConfig {
        id
        pms {
          id
          name
        }
      }
    }
  }
`;

export const GET_PROPERTIES = gqlTag`
  {
    propertiesList {
      id
      name
      status
      propertySettings {
        id
        logo
      }
    }
  }
`;

export const GET_USER_PROPERTIES = gqlTag`
  query usersPropertiesList($userId: Int!) {
    usersPropertiesList(condition: { userId: $userId }) {
      property {
        id
        name
        status
        pmsPropertyId
        propertySettings {
          id
          logo
        }
      }
    }
  }
`;

export const GET_USER_PROPERTIES_WITH_PMS = gqlTag`
  query usersPropertiesList($userId: Int!) {
    usersPropertiesList(condition: { userId: $userId }) {
      property {
        id
        name
        status
        propertySettings {
          id
          logo
        }
        propertyConfig {
          id
          pms {
            id
            name
          }
        }
      }
    }
  }
`;

export const PropertiesList = () => {
  const ability = useContext(AbilityContext);
  const { userId } = useContext(UserContext);

  let getPropertiesQuery;
  let getPropertiesOptions;

  const loadedAll = ability.can('load', 'PropertiesList');

  if (loadedAll) {
    getPropertiesQuery = ability.can('load', 'Property', 'config')
      ? GET_PROPERTIES_WITH_PMS
      : GET_PROPERTIES;
  } else {
    getPropertiesQuery = ability.can('load', 'Property', 'config')
      ? GET_USER_PROPERTIES_WITH_PMS
      : GET_USER_PROPERTIES;
    getPropertiesOptions = {
      variables: { userId },
    };
  }

  const { loading, error, data, refetch } = useQuery(
    getPropertiesQuery,
    getPropertiesOptions,
  );

  let propertiesList;

  const unpackUserProperties = userProperties =>
    userProperties.map(({ ...userProperty }) => ({
      ...userProperty.property,
    }));

  if (data) {
    propertiesList = loadedAll
      ? data.propertiesList
      : unpackUserProperties(data.usersPropertiesList || []);
  }

  return (
    <div>
      {loading && <Spinner color="secondary" className="mt-4" />}
      {error && <ErrorLine error={error} className="mt-4" />}
      {data && <Properties propertiesList={propertiesList} refetch={refetch} />}
    </div>
  );
};

const Properties = ({ propertiesList, refetch }) => (
  <>
    <SEO title="Properties list" />
    <div className="row" style={{ marginBottom: '-1px' }}>
      {propertiesList.map((property, i) => (
        <PropertyCard property={property} key={`propertiesList-${i}`} />
      ))}
      <Can I="create" a="Property">
        <AddPropertyButton refetch={refetch} />
      </Can>
    </div>
  </>
);

export default PropertiesList;
