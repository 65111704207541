import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { navigate } from 'gatsby';
import PropertyLogo from '../components/propertyLogo';
import '../styles/propertyCard.css';

const PropertyCard = ({ property }) => {
  const { id, name, status, propertyConfig: { pms } = {} } = property || {};

  const propertyStatuses = {
    ACTIVE: 'lightgreen',
    BLOCKED: 'red',
    DELETED: 'red',
    SUSPENDED: 'orange',
  };

  return (
    <div className="col-sd-12 col-md-6 col-lg-4 col-xl-3 p-0">
      <div
        onClick={() => {
          navigate(`/property/${id}`);
        }}
        style={{ height: '100%' }}
        className="property-card p-3 border-right border-bottom"
      >
        <div className="d-flex flex-row align-items-start property-card-body">
          <PropertyLogo property={property} size="small" />
          <div className="property-information">
            <div className="property-title">{name}</div>
            <div className="d-flex flex-row align-items-start property-text">
              <div>
                <span
                  id={`status-${id}`}
                  style={{
                    marginTop: '0.4rem',
                    width: '8px',
                    height: '8px',
                    borderRadius: '50%',
                    backgroundColor: propertyStatuses[status],
                    display: 'block',
                  }}
                />
                <UncontrolledTooltip target={`status-${id}`}>
                  {status}
                </UncontrolledTooltip>
              </div>
              <div>
                <span>ID: </span>
                {id}
              </div>
              {pms && (
                <div>
                  <span>PMS: </span>
                  {pms.name}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyCard;
