import React from 'react';
import { UncontrolledAlert } from 'reactstrap';

const ErrorLine = ({ error, ...props }) => {
  let errorTitle = null;
  let errorText = null;
  const defaultError = 'Uncaught error. Try again later.';

  if (error) {
    if (error.networkError && error.networkError !== 'undefined') {
      errorTitle = 'Network error';
      errorText = error.networkError.message || defaultError;
    } else if (error.graphQLErrors && error.graphQLErrors !== 'undefined') {
      errorTitle = 'Data error';
      errorText = error.graphQLErrors.map(({ message }, index) => (
        <span key={index.toString()}>{message}</span>
      ));
    } else {
      errorTitle = 'Error';
      errorText = error.message || defaultError;
    }
  } else {
    errorTitle = 'Error';
    errorText = defaultError;
  }

  return (
    <UncontrolledAlert color="danger" {...props}>
      <h4 className="alert-heading">{errorTitle}</h4>
      <p className="mb-0">{errorText}</p>
    </UncontrolledAlert>
  );
};

export default ErrorLine;
